@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

@font-face {
  font-family: 'Effra';
  src: url('../fonts/Effra/Effra\ Medium.ttf') format('truetype');
  font-weight: 700;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  span {
    font-family: 'Inter';
  }
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    @apply -translate-y-2 text-xsmall-regular;
  }

  input:focus ~ label {
    @apply left-0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid #212121;
    -webkit-text-fill-color: #212121;
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  .font-effra-bold {
    font-family: 'Effra', sans-serif;
    color: '#22436D';
  }

  .font-effra-normal {
    font-family: 'Effra', sans-serif;
  }

  .font-inter {
    font-family: 'Inter', sans-serif;
  }

  input {
    @apply font-inter;
  }
}

@layer components {
  .content-container {
    @apply max-w-[1440px] w-full mx-auto px-8;
  }

  .contrast-btn {
    @apply px-4 py-2 border border-black rounded-full hover:bg-black hover:text-white transition-colors duration-200 ease-in;
  }

  .text-xsmall-regular {
    @apply text-[10px] leading-4 font-normal;
  }

  .text-small-regular {
    @apply text-xs leading-5 font-normal;
  }

  .text-small-semi {
    @apply text-xs leading-5 font-semibold;
  }

  .text-base-regular {
    @apply text-sm leading-6 font-normal;
  }

  .text-base-semi {
    @apply text-sm leading-6 font-semibold;
  }

  .text-large-regular {
    @apply text-base leading-6 font-normal;
  }

  .text-large-semi {
    @apply text-base leading-6 font-semibold;
  }

  .text-xl-regular {
    @apply text-2xl leading-[36px] font-normal;
  }

  .text-xl-semi {
    @apply text-2xl leading-[36px] font-semibold;
  }

  .text-2xl-regular {
    @apply text-[30px] leading-[48px] font-normal;
  }

  .text-2xl-semi {
    @apply text-[30px] leading-[48px] font-semibold;
  }

  .text-3xl-regular {
    @apply text-[36px] leading-[48px] font-normal;
  }

  .text-3xl-semi {
    @apply text-[36px] leading-[48px] font-semibold;
  }
  .course-title {
    line-height: 4rem !important;
  }
  .course-percentage {
    line-height: 0rem !important;
    font-weight: bold !important;
    color: #344054 !important;
  }
}

ul[role='list'] {
  list-style: disc;
}

.sidebar-expanded {
  height: 100vh;
}

.sidebar-transition {
  transition: height 0.3s ease-in-out;
}

div > img {
  float: right;
  shape-outside: content-box;
  width: 20rem;
}
